<template>
  <v-form @submit.prevent="list(true)">
    <v-container>
      <v-row>
        <v-col :cols="12">
          <span class="text-h5">
            Assinantes
            <v-btn
              fab
              color="primary accent-2"
              x-small
              @click="addItem"
              v-auth="'SUBSCRIBER.ADD'"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :sm="9">
          <v-form @submit.prevent="list(true)">
            <v-card>
              <v-card-title class="card-title">
                <v-icon class="mr-1">mdi-magnify</v-icon>
                Filtro</v-card-title
              >
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      label="Nome"
                      v-model="subscriberList.filter.name"
                      v-debounce:300ms="list"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-select
                      :items="data.subscriptionPlans"
                      label="Plano"
                      v-model="subscriberList.filter.subscription_plan_id"
                      item-value="id"
                      item-text="name"
                      @change="list"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <v-select
                      :items="data.subscriberStatus"
                      label="Status"
                      v-model="subscriberList.filter.status"
                      item-value="id"
                      item-text="name"
                      @change="list"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-checkbox
                      v-model="subscriberList.filter.deleted"
                      label="Apenas excluídos"
                      @change="list"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-col>
        <v-col cols="12" :sm="3">
          <v-card elevation="2">
            <v-card-title class="card-title">
              <v-icon class="mr-1">mdi-format-list-numbered-rtl</v-icon>
              Resumo
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="yellow darken-2"
                    v-bind="attrs"
                    v-on="on"
                    style="cursor: pointer"
                    >mdi-help-circle</v-icon
                  >
                </template>
                <span>Assinantes de teste são excluídos das contagens</span>
              </v-tooltip>
            </v-card-title>
            <v-card-text>
              Assinantes ativos:
              <strong>{{ context.active_subscribers }}</strong
              ><br />
              Assinantes inativos:
              <strong>{{ context.inactive_subscribers }}</strong
              ><br />
              Dispositivos:
              <strong>{{ context.devices }}</strong>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col :cols="12">
          <v-card>
            <v-card-text>
              <Paginator
                v-bind.sync="subscriberList.pagingOptions"
                @page="pageSelected"
              ></Paginator>

              <v-data-table
                :headers="subscriberList.headers"
                :items="subscriberList.data"
                :items-per-page="25"
                :disable-sort="true"
                :loading="subscriberList.loading"
                no-data-text=""
                loading-text="Carregando dados..."
                class="app-table hover-table"
                @click:row="viewItem"
                hide-default-footer
              >
                <template v-slot:[`item.errors`]="{ item }">
                  <SubscriberRegistrationWarning
                    :subscriber="item"
                  ></SubscriberRegistrationWarning>
                </template>

                <template v-slot:[`item.name`]="{ item }">
                  <div>
                    <span class="list-item-title"
                      >{{ item.first_name }} {{ item.last_name }}
                    </span>
                    <v-tooltip bottom v-if="item.test_user">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="red lighten-2"
                          v-bind="attrs"
                          v-on="on"
                          style="cursor: pointer"
                          >mdi-account-hard-hat</v-icon
                        >
                      </template>
                      <span>Assinante de teste</span>
                    </v-tooltip>
                    <br />
                    <span class="list-item-subtitle">{{ item.cpf }}</span>
                  </div>
                </template>
                <template v-slot:[`item.subscription_plan`]="{ item }">
                  {{ item.subscription_plan_name }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                        v-if="item.multiple"
                        >mdi-star-circle</v-icon
                      >
                    </template>
                    <span>Possui outros planos</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.contact`]="{ item }">
                  <span class="list-item-title">{{ item.email }}</span
                  ><br />
                  <span class="list-item-subtitle">{{
                    item.phone | phone
                  }}</span>
                </template>
                <template v-slot:[`item.subscribed_at`]="{ item }">
                  {{ item.subscribed_at | defaultDate("DD/MM/YYYY HH:mm") }}
                </template>
                <template v-slot:[`item.expires_at`]="{ item }">
                  {{ item.expires_at | defaultDate("DD/MM/YYYY HH:mm") }}
                </template>
                <template v-slot:[`item.deleted_at`]="{ item }">
                  {{ item.deleted_at | defaultDate("DD/MM/YYYY HH:mm") }}
                </template>
                <template v-slot:[`item.subscription_status`]="{ item }">
                  <v-chip
                    small
                    :color="
                      item.subscription_status
                        | enum(data.subscriberStatus, 'id', 'class')
                    "
                    dark
                    >{{
                      item.subscription_status | enum(data.subscriberStatus)
                    }}</v-chip
                  >
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { eventHub } from "@/main";
import subscriberApi from "@/common/api/subscriber.api";
import subscriptionPlanApi from "@/common/api/subscription-plan.api";
import clientApi from "@/common/api/client.api";
import subscriberStatus from "@/common/data/subscriber-status.data";
import userApi from "@/common/api/user.api";
import Paginator from "@/components/layout/Paginator";
import SubscriberRegistrationWarning from "@/components/subscriber/SubscriberRegistrationWarning.vue";

export default {
  name: "SubscriberList",

  components: { Paginator, SubscriberRegistrationWarning },

  data: () => ({
    data: {
      subscriberStatus: [{ id: "", name: "Todos" }, ...subscriberStatus],
      subscriptionPlans: [],
    },
    context: { provider: {} },
    subscriberList: {
      filter: {
        subscription_plan_id: "",
        status: "",
      },
      headers: [],
      data: [],
      loading: false,
      pagingOptions: {
        currentPage: 1,
      },
    },
  }),

  beforeDestroy() {
    eventHub.$off("SELECTED_CLIENT");
  },

  mounted() {
    eventHub.$on("SELECTED_CLIENT", () => {
      this.loadSubscriptionPlans();
      this.list(true);
    });
  },

  created() {
    this.list();
    this.loadSubscriptionPlans();
  },

  methods: {
    pageSelected(page) {
      this.subscriberList.pagingOptions.currentPage = page;
      this.list();
    },

    addItem() {
      this.$router.push(`assinantes/novo`);
    },

    setHeaders() {
      this.subscriberList.headers = [];

      this.subscriberList.headers.push({
        text: "",
        value: "errors",
        class: "icon-cell",
        cellClass: "icon-cell",
      });
      this.subscriberList.headers.push({ text: "Nome", value: "name" });
      //   this.headers.push({ text: "Contato", value: "contact" });
      this.subscriberList.headers.push({
        text: "Plano",
        value: "subscription_plan",
      });
      this.subscriberList.headers.push({
        text: "Data ativação",
        value: "subscribed_at",
      });

      if (
        userApi.getUserModule() === "BACKOFFICE" &&
        !this.subscriberList.filter.deleted
      ) {
        this.subscriberList.headers.push({
          text: "Prazo ativação",
          value: "expires_at",
        });
      }

      if (this.subscriberList.filter.deleted) {
        this.subscriberList.headers.push({
          text: "Data exclusão",
          value: "deleted_at",
        });
      }

      this.subscriberList.headers.push({
        text: "Status",
        value: "subscription_status",
      });
    },

    loadSubscriptionPlans() {
      let filter = {
        providerId: clientApi.getSelectedClient().id,
        status: "A",
        size: -1,
      };

      subscriptionPlanApi.list(filter).then((result) => {
        this.data.subscriptionPlans = [
          { id: "", name: "Todos" },
          ...result.data.data,
        ];
      });
    },

    list(resetPage) {
      this.setHeaders();

      if (resetPage) {
        this.subscriberList.pagingOptions.currentPage = 1;
      }

      let client = clientApi.getSelectedClient().id;

      if (client) {
        this.subscriberList.filter.providerId = client;
        this.subscriberList.filter.page = this.subscriberList.pagingOptions.currentPage;

        this.subscriberList.loading = true;
        subscriberApi.list(this.subscriberList.filter).then((result) => {
          this.subscriberList.loading = false;
          this.subscriberList.data = result.data.data;
          this.context = result.data._context;
          this.subscriberList.pagingOptions = result.data.pagingOptions;
        });
      }
    },

    viewItem(item) {
      this.$router.push(`assinantes/${item.id}`);
    },
  },
};
</script>

<style lang="scss">
.icon-cell {
  padding-right: 0px !important;

  & + td,
  & + th {
    padding-left: 8px !important;
  }
}
</style>
